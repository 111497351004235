@import '~froala-editor/css/froala_style.min.css';

.fr-view {
  word-wrap: break-word;
}

.fr-view a {
  color: #2563eb; /* blue600 */
  font-weight: 600;
}

.fr-view li + li {
  margin-top: 0.25em;
}

.fr-view a:hover {
  color: #1e40af; /* blue800 */
  text-decoration: underline;
}

.fr-view [data-mention-user-id] {
  background-color: #ebf8ff;
  border-radius: 3px;
  color: #334e68;
  cursor: pointer;
  padding: 2px 6px 2px 2px;
}

.fr-view [data-mention-user-id]:before {
  content: '@';
  opacity: 0.5;
  line-height: 1;
  padding-right: 2px;
}

.fr-view .videoEmbed-container {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  max-width: 100%;
  width: 100%;
}

.fr-view .videoEmbed-container iframe,
.fr-view .videoEmbed-container object,
.fr-view .videoEmbed-container embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
